@import '../variables';

.about {
    margin-top: 4rem;
    margin-bottom: 5rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }

    .title_row {
        margin-left: -4rem;
        position: relative;
        bottom: -13rem;
        margin-bottom: 7.5rem;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            margin: 0;
            bottom: 0;
        }

        h1 {
            text-align: left;
        }
    }
}

section.about_airplane {
    margin: 20px 0 -110px 0;
    position: relative;
    width: 100%;
    height: 210px;
    top: -110px;
    display: flex;
    align-items: center;
    justify-content: center;

    img.airplane {
        position: absolute;
        right: 0;
        top: 0;
        width: 300px;
        transform: scaleX(-1);
    }

    &.about_airplane--content {
        top: 0;
        margin: 0;
        height: 100%;
        position: relative;

        img.airplane {
            position: relative;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 4;
        }

        .airplane_circle {
            background: #00325F66;
            position: absolute;
            border-radius: 50%;
            margin: 0;
            width: 170px;
            height: 170px;
            @include media-breakpoint-up(sm) {
                width: 210px;
                height: 210px;
            }
            @include media-breakpoint-up(md) {
                margin-left: 5rem;
                width: 300px;
                height: 300px;
            }
        }
    }

}

.initiative {
    overflow: hidden;
    padding-bottom: 1rem;

    .spotlight__header {
        position: relative;
        text-align: center;
        margin-bottom: 3rem;

        h1 {
            color: #FFFFFF;
            font-weight: 900;
            font-size: 2rem;
            line-height: 50px;
        }

        h4 {
            font-weight: 400;
            color: #FFFFFF;
        }
    }

    .spotlight {
        position: relative;

        &__social {
            h1 {
                color: $color-main-yellow;
                font-size: 1.5rem;
            }

        }

        & > div {
            position: relative;
            display: flex;
            align-items: center;
            height: 350px;
            justify-content: space-between;
        }

        &__diederik {
            z-index: 1;
            text-align: left;
            flex-direction: row-reverse;

            .spotlight__image {
                left: -30px;
            }
        }

        &__alexander {
            z-index: 2;
            text-align: left;
            flex-direction: row-reverse;

            .spotlight__image {
                left: 30px;
            }
        }

        &__pascal {
            z-index: 1;
            text-align: right;
            flex-direction: row;
        }

        &__image {
            height: 100%;
            position: relative;

            &--left {
                left: 0;
            }

            &--right {
                right: 30px;
            }
        }

        &__social {
            position: relative;
            z-index: 10;
        }

        .floor {
            width: 70%;
            position: absolute;
            bottom: 20px;
            z-index: -1;
            transform: scaleY(2);

            &__diederik {
                left: -15%;
            }

            &__alexander {
                left: -20%;
            }

            &__pascal {
                right: -10%;
            }
        }
    }

    @include media-breakpoint-up(sm) {

        .spotlight {
            & > div {
                justify-content: space-evenly;
                height: 450px;
            }

            &__social {
                h1 {
                    font-size: 2rem;
                }

            }

            &__image {
                &--left {
                    left: 0;
                }

                &--right {
                    right: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {

        .spotlight__header {
            margin-bottom: 4rem;

            h1 {
                font-size: 4rem;
            }
        }

        .spotlight {

            height: 540px;
            display: flex;
            width: 100%;
            justify-content: center;

            & > div {
                position: absolute;
                justify-content: unset;
            }

            &__social {
                position: absolute;

                h1 {
                    font-size: 1.7rem;
                }
            }

            &__diederik {
                bottom: 20px;
                left: 0;

                &__social {
                    top: -80px;
                    left: -20px;
                }

                img {
                    left: -70px;
                }
            }

            &__alexander {
                bottom: 20px;
                justify-content: center;

                &__social {
                    top: -80px;
                    left: 0;
                }
            }

            &__pascal {
                bottom: 20px;
                right: 0;

                &__social {
                    top: -80px;
                    left: 150px;
                    text-align: left;
                }

                img {
                    right: -50px;
                }
            }

            .floor {
                width: 100%;
                position: absolute;
                bottom: 0;
                z-index: 0;
                transform: scaleY(1);

                &__diederik {
                    display: none;
                }

                &__alexander {
                    display: none;
                }

                &__pascal {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {


        padding-bottom: 5rem;
        .spotlight {
            .floor {
                bottom: -70px;
            }
        }


        .spotlight__header {
            margin-bottom: 14rem;
        }

        .spotlight {

            & > div {
                height: 620px;
            }

            &__social {
                h1 {
                    font-size: 2rem;
                }
            }

            &__diederik {
                &__social {
                    left: -10px;
                    top: -10px;
                    text-align: right;
                }
            }

            &__pascal {
                &__social {
                    left: 110px;
                    top: 0;
                    text-align: right;
                }
            }

            &__alexander {
                &__social {
                    top: -80px;
                    left: 0;
                }
            }
        }
    }
}

section.team {

    .team__person {
        display: flex;
        flex-direction: column;
        align-items: center;

        .team__pfp {
            background: $color-main-yellow;
            box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
            overflow: hidden;
            width: 225px;
            height: 225px;
            position: relative;
            z-index: 2;

            img {
                width: 100%;
                height: 100%;
            }


        }

        .team__social {
            margin: 15px 0 0 0;
            position: absolute;
            top: 12rem;
            z-index: 3;

            a {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 20px 20px;
                display: inline-block;
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }

            & > a {
                background-color: #FFFFFF;
                box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.15);
                margin: 5px;
            }

            .linkedin {
                background-image: url('../img/frontend/icons/linkedin.svg');
                background-size: 20px 20px;
            }
        }

        .team__info {
            display: flex;
            flex-direction: column;
            background: $color-light-gray;
            width: 12rem;
            padding: 6rem 1rem 1rem;
            position: relative;
            top: -4rem;
            z-index: 1;

            h2 {
                color: $color-main-blue;
            }

            color: $color-main-blue;
        }
    }
}

section.about_corpoflow {
    overflow-x: hidden;

    .title_row {
        h1 {
            text-align: left;
        }

        h4 {
            font-size: 1.2rem;
        }

        &__cf-logo {
            margin: 1rem 0;
            width: 15rem;
        }
    }

    section.about_corpoflow--preview {
        position: relative;

        img {
            width: 650px;
            @include media-breakpoint-up(sm) {
                width: 960px;
            }
        }
    }
}
