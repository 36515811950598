@import '../variables';

$footer-icon-width: 22px;

.button {
    padding: 8px 30px;
    text-align: center;
    width: auto;
    white-space: nowrap;
    font-size: $font-size-body;
    border-radius: 50px 0 50px 50px;
    background: transparent;
    color: var(--bs-dark-green);
    display: inline-block;
    position: relative;
    z-index: 1;
    border: solid 1px transparent;
    text-decoration: none;
    box-shadow: var(--bs-box-shadow);

    &--larger {
        font-size: 16px;
        padding: 8px 25px;

        .button_icon {
            left: 22px;
            top: 10px;

            &.button_icon--small {
                left: 24px;

                img {
                    width: 18px;
                }
            }
        }
    }


    &--contact {
        color: var(--bs-main-green);
        background: var(--bs-grey-green);

        span {
            font-weight: 300;
        }
    }

    span {
        margin-right: auto;
        //margin-left: auto;

        @include media-breakpoint-up(md) {
            margin-left: unset;
        }
    }

    &__icon {

        //display: none;
            display: block;

        @include media-breakpoint-up(md) {
        }

        img {
            width: 22px;
        }
    }

    &__text {
        position: relative;
        font-size: 1.2rem;
        font-weight: 500;

        // Calc negative number
        @include media-breakpoint-up(md) {
            left: calc((-1 * $footer-icon-width) / 2);
        }
    }

    &.button--cta {
        border-color: var(--bs-main-green);
        background: var(--bs-main-green);
        font-weight: 600;
        font-size: 1.1rem;
    }

    &.button--form_send {
        font-size: 1.2rem;
        font-weight: 600;
    }

    &.button--full-width {
        display: block;
        width: 100%;
    }

    &.button--outline {
        color: var(--bs-dark-green);
        border-color: var(--bs-dark-green);
        font-size: 1rem;
    }

}
