@import '../variables';

.modal-form,.contact-taxatie-form {

    form {

        label {
            color: var(--bs-dark-green);
            font-weight: 300;
            font-size: 1.1rem;
            margin-bottom: 0.2rem;
        }

        input[type='text'] {

            appearance: none;
            width: 100%;
            border: none;
            border-radius: var(--bs-border-radius);
            color: var(--bs-dark-green);
            padding: 10px 15px;
            margin-bottom: 1rem;

            &::placeholder {
                color: var(--bs-gray-600);
            }

        }

        button {
            outline: none;
            font-weight: bold;

            &.button {
                box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.15);
            }
        }

        .error-message {
            margin-top: -10px;
            margin-bottom: 15px;
            color: red;
        }

        textarea {
            border: none;
            resize: none;
            width: 100%;
            border-radius: var(--bs-border-radius);
            padding: 1rem;
        }

        input[type="text"], textarea {
            &.form-error {
                border-color: red;
            }

            &:focus {
                outline: none;
                border-color: $color-main-blue;
            }
        }

        a {
            appearance: none;
            border: none;
            border-radius: var(--bs-border-radius);
            background: var(--bs-main-green);
            color: var(--bs-dark-green);
        }

        #ApplicationUpload {
            display: none;
        }

    }

    .disabled-button {
        background: gray;
        border-color: gray;
    }
}
