@import '../variables';

.bg-body-gray {
    background: $bg-body-gray;
}

.bg-body-graydient {
    background: linear-gradient(#ffffff, #f2f2f2);

    margin: -1px 0;

    &.bg-body-graydient--reverse {
        background: linear-gradient(#f2f2f2, #ffffff);
    }
}

.bg-light-graydient {
    background: linear-gradient(90deg, #fafafa 25.68%, white 87.3%)
}

.bg-green-gradient-top {
    background: #27AE60;
    margin: -1px 0;
}

.bg-green-gradient-bottom {
    background: #1FC967;
    margin: -1px 0;
}

.bg-green-gradient {
    background: linear-gradient(180deg, #27AE60 0%, #1FC967 100%);
    margin: -1px 0;
}


.bg-blue-gradient-top {
    background: $color-main-blue;
    margin: -1px 0;
}

.bg-blue-gradient-bottom {
    background: $color-light-blue;
    margin: -1px 0;
}

.bg-blue-gradient--left-right {
    background: linear-gradient(90deg,#000000 0%, $color-main-blue 100%);
    margin: -1px 0;
}

.bg-blue-gradient {
    background: linear-gradient(180deg, $color-main-blue 44.28%, $color-light-blue 79.63%);
    margin: -1px 0;
}

.bg-gray {
    background: #E8EDF2;
}


.bg-curve-wrapper-bottom-green-to-white {
    background: linear-gradient(#1FC967, #ffffff);
    margin: -1px 0;
}

.bg-cta {
    background: $color-call-to-action;
    margin: -1px 0;
}


.bg-trans-green {
    background: var(--bs-transp-green);
}
