@import '../variables';

.logo_row {
    padding: 25px 0 0 0;
    position: relative;
    z-index: 2;
    background: white;

    img.logo {
        padding: 5px;
        position: relative;
        max-width: 150px;
    }
}
