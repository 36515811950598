.jobs {


    &__block {
        margin-top: 9rem;
        padding: 2rem 1rem;
        position: relative;

        &:first-child {
            margin-top: 3rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 5rem;
        }

        &__text-container {
            &__title {
                font-size: 2.5rem;
            }

            span {
                //color: var(--bs-dark-green);
            }

            b {
                margin-bottom: 1rem;
            }

            p, b {
                line-height: 30px;
            }

        }

        &__image-mob-container {

            $image_size: 250px;

            position: absolute;
            clip-path: circle(50%);
            width: $image_size;
            height: $image_size;

            background: var(--bs-light-green);
            transform: translate(-50%, -50%);
            top: -70px;
            left: 50%;

            @include media-breakpoint-up(sm) {
                left: unset;
                right: -20px;
            }
        }

        &__list {
            list-style-type: '- ';
        }

    }

}