@import '../variables';

.top_menu {
    padding: 45px 0 0 0;

    .top-menu_container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .top-menu_items {
        display: flex;
        justify-content: flex-end;
    }

    div.menu_dot {
        width: 46px;
        height: 46px;
        display: block;
        border-radius: 50%;
        background-color: var(--bs-main-green);
        background-image: url('../img/frontend/menu-dot-lines.svg');
        background-repeat: no-repeat;
        background-position: 50%;
    }

    div.login_demo_switch {
        float: right;
        position: relative;
        padding: 0 55px 0 0;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            padding: 0 130px 0 0;
            margin: -9px 0 0 0;
        }

        a {
            white-space: nowrap;

            &:hover {
                text-decoration: none;
            }
        }

        a.contact {
            display: block;
            color: var(--bs-dark-green);
            font-weight: 600;
            text-align: center;
            padding: 8px 30px;
            border-radius: var(--bs-border-radius);
            background: var(--bs-main-green);
            font-size: 15px;
            position: absolute;
            right: 0;
            top: 0;
            text-decoration: none;
        }
    }

    ul.menu {
        list-style: none;
        padding: 0;
        float: left;
        margin: 0 0 0 15px;

        &--desktop {
            .jobs_count {

                $orb_size_desktop: 15px;

                background: var(--bs-main-green);
                color: var(--bs-dark-green);

                height: $orb_size_desktop;
                width: $orb_size_desktop;
                font-size: 0.9rem;
                top: -7px;
                right: -14px;
            }

        }

        li {
            display: inline-block;
            margin: 0 8px;
            position: relative;

            @include media-breakpoint-up(lg) {
                margin: 0 12px;
            }
            @include media-breakpoint-up(xl) {
                margin: 0 18px;
            }

            &:first-child {
                margin-left: 0;
            }

            &.active {
                a {
                    text-decoration: underline;
                }
            }

            a {
                color: #ffffff;
                line-height: 16px;
                font-size: 15px;
                text-decoration: none;
            }
        }
    }
}

div.default_top_spacer {
    min-height: 20px;

}
