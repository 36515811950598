.nieuws-detail {

    img {
        height: 560px;
        margin-top: -140px;
        object-fit: cover;

        @include media-breakpoint-up(md) {
            margin-top: -280px;
        }

        @include media-breakpoint-up(lg) {
            height: 540px;
            margin-top: -200px;
        }

    }

    &__wrapper {

        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 1rem 4rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 1rem 10rem;
        }

        p {
            margin: 0;
        }

    }

    &__title {
        font-size: 2.5rem;
        font-weight: 600;
    }

}