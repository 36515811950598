.dit-zijn-wij-intro {
    padding-bottom: 0;

    &__wrapper {
        margin-top: -10rem;

        .row {
            justify-content: center;
            flex-wrap: nowrap;
        }
    }

    &__image {
        height: 70vh;
        object-fit: cover;
        object-position: right;
        position: relative;
        z-index: 4;
    }

    &__text-container {
        font-size: 1.1rem;
        margin-top: 8rem;
        //margin-left: 2rem;
        //margin-right: 2rem;

        //@include media-breakpoint-up(md) {
        //    margin-top: 4rem;
        //}

    }

}

.dit-zijn-wij-employees {

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        grid-auto-rows: 1fr;
        justify-items: center;
        gap: 1rem;
        column-gap: 1.5rem;
    }


    &__block {
        height: 100%;
        width: 100%;
        position: relative;
        display: grid;
        padding: 0.75rem;
        grid-auto-rows: max-content;
        overflow: hidden;

        .overlay {
            height: 0;
            width: 100%;
            position: absolute;
            background: linear-gradient(rgb(12 40 4 / 0) 50%, rgb(12 40 4), rgb(12, 40, 4));
            bottom: 0;
            left: 0;
        }

        &__photo--unknown {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }

        $shape-size: 270px;

        &__photo-container {
            width: $shape-size;
            height: $shape-size;
            clip-path: circle(45%);
            border-radius: 50%;
            position: relative;
            justify-self: center;
            margin-bottom: -27px;

            .round_shape {
                background: rgb(242 244 241 / 1);
                width: $shape-size;
                height: $shape-size;
                clip-path: circle(45%);
                border-radius: 50%;
                position: absolute;
            }



            img {
                max-width: 100%;
                height: auto;
            }
        }

        &__contact-container {
            $contact_height: 30px;

            margin-left: 20px;
            display: flex;
            gap: 1rem;
            opacity: 0;
            height: $contact_height;
            position: relative;
            bottom: 0;
            transform: translateX(-100%);
            z-index: 99;
            margin-bottom: 10px;

            a {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 20px 20px;
                display: inline-block;
                width: $contact_height;
                height: $contact_height;
                border-radius: 50%;
                border: 1px solid var(--bs-main-green);
            }

            $icon_size: 15px;

            .phone {
                background-image: url('../img/frontend/icons/phone-green.svg');
                background-size: $icon_size $icon_size;
            }

            .linkedin {
                background-image: url('../img/frontend/icons/linkedin.svg');
                background-size: $icon_size $icon_size;
            }

            .email {
                background-image: url('../img/frontend/icons/email-green.svg');
                background-size: $icon_size $icon_size;
            }
        }

        $name-size: 1.5rem;

        &__text-container {
            display: grid;
            grid-template-columns: 20px 1fr;
            position: relative;
            z-index: 999;

            @include media-breakpoint-up(xl) {
                grid-template-columns: 20px 250px;
            }

            .slash {
                grid-column: 1;
                font-size: $name-size;
            }
        }

        &__name {
            font-size: $name-size;
            font-weight: 500;
            grid-column: 2;
            color: var(--bs-dark-green);

        }

        &__function {
            align-self: flex-start;
            font-size: 1rem;
            font-weight: 300;
            grid-column: 2;
            color: var(--bs-dark-green);
            line-height: 23px;
            display: block;
        }

        hr {
            grid-column: 2;
            width: 25%;
            margin: 0.5rem 0;
        }

        &__realtor {
            color: var(--bs-grey-green);
            font-style: italic;
            font-weight: 200;
            grid-column: 2;
        }

    }

}

// Custom mixin to generate container-fluid-left and container-fluid-right (fluid on the left/right and normal max width on the other)
// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-fluid-side-max-widths($side, $max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    margin-#{$side}: 0;
    padding-#{$side}: 0;

    .row{
        margin-#{$side}: 0;
    }

    .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto{
        padding-#{$side}: 0;
    }

    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: calc( 100% - (100% - #{$container-max-width}) / 2 );
        }
    }
}


.container-fluid-left {
    @include make-container();
    @include make-container-fluid-side-max-widths('left');
}

.container-fluid-right {
    @include make-container();
    @include make-container-fluid-side-max-widths('right');
}