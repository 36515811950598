@import '../variables';


div.curve_wrapper {

    &.curve--shadow {
        &:before {
            content: '';
            display: block;
            height: 130px;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background: rgba(0, 0, 0, .8);
            background: -moz-linear-gradient(top, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, .8)), color-stop(0%, rgba(0, 0, 0, .8)), color-stop(100%, rgba(0, 0, 0, 0)));
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 100%);
            background: -o-linear-gradient(top, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 100%);
            background: -ms-linear-gradient(top, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
            z-index: 1;
        }
    }

    .top_menu {
        position: relative;
        z-index: 2;
    }

    .curve_wrapper_spacer {
        height: 50px;

        &.curve_wrapper_spacer--1 {
            height: 100px;
        }

        &.curve_wrapper_spacer--2 {
            height: 150px;
        }
    }

    &.curve_wrapper--curve-bottom {
        border-bottom-left-radius: 75% 68%;
        border-bottom-right-radius: 67% 42%;
    }

    &.curve_wrapper--curve-bottom-v2 {
        border-bottom-left-radius: 75% 48%;
        border-bottom-right-radius: 48% 92%;
    }

    &.curve_wrapper--curve-header {
        border-bottom-left-radius: 65% 98%;
        border-bottom-right-radius: 52% 42%;
    }

    &.curve_wrapper--curve-blog-image {
        border-bottom-left-radius: 175% 88%;
        border-bottom-right-radius: 62% 32%;
        background-attachment: fixed;
        background-clip: border-box;
        background-position: center center !important;
        background-size: 100% !important;
    }

    &.curve_wrapper--curve-footer {
        border-top-left-radius: 60% 100%;
        border-top-right-radius: 40% 90%;
    }

    &.curve_wrapper--curve-bottom-sharp {
        border-bottom-left-radius: 55% 18%;
        border-bottom-right-radius: 47% 8%;
    }

    &.curve_wrapper--curve-top {
        border-top-left-radius: 84% 98%;
        border-top-right-radius: 75% 72%;
    }

    &.curve_wrapper--curve-top-long {
        border-top-left-radius: 230% 366%;
        border-top-right-radius: 133% 104%;
    }

    &.curve_wrapper--curve-top-long-reverse {
        border-top-left-radius: 50% 40%;
        border-top-right-radius: 50% 100%;
    }

    &.curve_wrapper--curve-top-right {
        border-top-left-radius: 59% 18%;
        border-top-right-radius: 45% 17%;
    }

    &.curve_wrapper--curve-half-top {
        border-top-left-radius: 50% 79%;
        border-top-right-radius: 50% 87%;
    }

    &.curve_wrapper--blue {
        background: linear-gradient(90deg,#000000 0%, $color-main-blue 100%);
    }

    &.curve_wrapper--green {
        background: linear-gradient(180deg, #27AE60 0%, #1FC967 100%);
    }

    &.curve_wrapper--gray {
        background: #E8EDF2;
    }

    &.curve_wrapper--light-gray {
        background: linear-gradient(90deg, #fafafa 25.68%, rgba(0, 0, 0, .003) 87.3%)
    }

    &.curve_wrapper--white {
        background: white;
    }

    &.curve_wrapper--outside {
        position: absolute;
        left: -20px;
        right: -20px;
        height: 100%;
    }

}

div.curve_overflow {
    overflow: hidden;
    position: relative;
}

div.curve_height {
    height: 52px;
    margin: -2px 0;

    &.curve_height--1 {
        height: 100px;
    }

    &.curve_height--gray {
        background: $color-bg-gray;
    }

    @include media-breakpoint-up(md) {
        &.curve_height--md-2 {
            height: 150px;
        }
        &.curve_height--md-3 {
            height: 200px;
        }
        &.curve_height--md-4 {
            height: 250px;
        }
        &.curve_height--md-5 {
            height: 300px;
        }
        &.curve_height--md-6 {
            height: 350px;
        }
    }

    @include media-breakpoint-up(lg) {
        &.curve_height--md-2 {
            height: 150px;
        }
        &.curve_height--md-3 {
            height: 200px;
        }
        &.curve_height--md-4 {
            height: 250px;
        }
        &.curve_height--md-5 {
            height: 300px;
        }
        &.curve_height--md-6 {
            height: 350px;
        }
    }
}
