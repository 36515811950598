@import '../variables';

footer {

    .copyright {
        padding: 3rem 0 0.5rem 0;
        font-size: 0.8rem;
    }

    section:not(.copyright) {
        padding-top: 3rem;
    }

    .footer__wrapper {
        .container {
            gap: 1.5rem;
        }
    }

    .footer_logo {
        margin: auto;
        @include media-breakpoint-up(md) {
            width: 250px;
        }
    }

    .footer_logo_text {
        opacity: .8;
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
    }

    .modules {
        font-size: 14px;
        line-height: 24px;
        color: #F2F2F2;
    }

    .about_taxgoed {
        padding: 0;
        font-size: 15px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            padding: 0 0 0 35px;
        }

        ul {
            display: grid;
            justify-content: center;
            justify-items: start;
            margin-top: 1rem;
            gap: 1rem;
            padding: 0;
            list-style: none;

            @include media-breakpoint-up(md) {
                justify-content: start;
            }
        }
    }

    .footer-partners {

        &__container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 1rem;
            align-items: center;
            justify-items: center;

            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(5, 1fr);
            }

        }

        img {
            max-width: 100%;
            height: 6rem;
            padding: 1rem;
            object-fit: contain;

            @include media-breakpoint-up(sm) {

                &:nth-child(5) {
                    grid-column-end: span 2;
                }

            }

            @include media-breakpoint-up(lg) {
                padding: unset;

                &:nth-child(5) {
                    grid-column-end: unset;
                }

            }

        }


    }

}

.ps {

    .copyright {

        p {
            font-size: 90%;
            color: $color-gray-text;
        }

        a {
            color: $color-gray-text;
            text-decoration: underline;
        }
    }

    .social_icons {
        position: relative;

        .curve_height {
            top: -30px;
        }

        a {
            z-index: 2;
            position: absolute;
            display: block;
            width: 46px;
            height: 46px;

            top: -41px;
            left: 50%;
            margin-top: -23px; /* Half the height */
            margin-left: -23px; /* Half the width */
            img {
                width: 100%;
                height: 100%;
            }

            &.facebook {
                margin-left: -110px;
                margin-top: -2px;
            }

            &.instagram {
                margin-left: -55px;
                margin-top: -10px;

            }

            &.twitter {
                margin-left: -45px;
                margin-top: -14px;
            }

            &.linkedin {
                margin-left: 2px;
                margin-top: -14px;
            }
        }

    }
}
