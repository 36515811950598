@import '../variables';

.welcome_cta {
    .welcome_cta-wrapper{
        display: flex;
        align-items: center;
        position: relative;
        bottom: 75px;
        @include media-breakpoint-down(sm) {
            justify-content: center;
            margin-top: 8rem;
        }
        h1 {
            margin-bottom: 0;
            color: $color-main-yellow;
            font-weight: 300;
            font-size: 1.2rem;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}
