@import '../variables';

section.title_row {

    &.title_row--block {
        position: relative;
        right: 2rem;
        top: 4rem;
        &.title_row--about {
            top: unset;
            @include media-breakpoint-down(sm) {
                right: unset;
            }
        }
        span.sub_title {
            color: $color-main-blue;
            font-weight: $sub-title-font-weight;
            font-size: $font-size-sub-title;
        }

        h1 {
            font-weight: 900;
            line-height: 45px;
            font-size: 3rem;
            text-align: left;
            color: $color-main-blue;
        }

        p {
            color: $color-description-text;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 38px;
            text-align: left;
        }

        ul {
            li {
                font-size: 18px;
                color: $color-description-text;
            }
        }
    }

    &.title_row--white {
        span.sub_title, p, h1 {
            color: white;
        }

    }

    &.title_row--blue {
        span.sub_title, p, h1 {
            color: #012647;
        }
    }

    span.sub_title {
        color: $color-sub-title-text;
        font-size: 18px;
        line-height: 27px;
        opacity: .8;
    }

    h1 {
        font-weight: 900;
        line-height: 45px;
        font-size: 3rem;
        text-align: center;
        color: $color-h1-text;
    }

    p {
        @extend .text-body-large;
    }

    .floating_icon {
        width: 55px;
        height: 55px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        line-height: 55px;

        img {
            display: inline-block;

        }
    }

    &.title_row--contact {
        span.sub_title, p, h1 {
            color: #012647;
        }
    }
}
