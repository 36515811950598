.modal-content {

    .close_modal_button {
        position: relative;
        right: 15px;
        top: 15px;
        cursor: pointer;
        color: #FFFFFF;
        align-self: flex-end;

        i {
            font-size: 2rem;
        }

    }

}
