@import '../variables';

.logo {

    text-align: center;
    position: relative;
    margin: 40px 0 0 0;

    &.logo--desktop {
        text-align: left;
        position: relative;
        margin: 0;
        img {
            width: 230px;
        }
    }

    img {
        width: 50%;
    }
}

