$color-body-text: #4F4F4F;
$color-sub-title-text: #333333;
$color-body-large-text: #9b9b9b;
$color-gray-text: #828282;

$color-h1-text: #333333;
$color-call-to-action: #FFEC01;
$color-main-blue: #012647;
$color-light-blue: #014883;
$color-main-yellow: #FFEC01;
$color-light-gray: #E8EDF2;

$color-bg-blue: #012747;
$color-bg-gray: #E8EDF2;

$color-description-text: #01274780;

$font-size-sub-title: 18px;
$sub-title-font-weight: 400;

$boxshadow: 3px 4px 4px rgba(0, 0, 0, 0.15);

$bg-body-gray: #f2f2f2;

$font-size-h1: 2rem;
$font-size-body: 14px;
$font-size-body-large: 16px;

$pallete: (
    'green': linear-gradient(180deg, #27AE60 0%, #1FC967 100%),
    'orange': linear-gradient(180deg, #F2994A 0%, #FF8314 100%),
    'purple': linear-gradient(180deg, #BB6BD9 0%, #9A38BE 100%),
    'blue': linear-gradient(180deg, #56CCF2 0%, #1DC8FF 100%),
    'yellow': linear-gradient(180deg, #FFED47 0%, #FCC75F 100%),
    'red': linear-gradient(180deg, #F25656 0%, #CB2626 100%),
);


$section-spacing: 3rem;
