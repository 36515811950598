@import '../variables';

div.header_down_arrow {
    width: 36px;
    height: 36px;
    display: block;
    left: 50%;
    border-radius: 50%;
    margin: -20px 0 0 -18px;
    background-color: white;
    background-image: url("../img/frontend/chevron-right-blue.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
    transform: rotate(90deg);
}

div.module_header_down_arrow {
    width: 36px;
    height: 36px;
    display: block;
    left: 50%;
    border-radius: 50%;
    margin: -65px 0 0 -18px;
    background-color: $color-light-blue;
    background-image: url("../img/frontend/chevron-right.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
    transform: rotate(90deg);

    &.module_header_down_arrow--lower {
        margin-top: -15px;
    }
}
