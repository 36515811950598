.building-value {

    &__wrapper {
        overflow: hidden;

    }

    &__block {

        &__image-container {

            img {
                max-width: 100%;
                //height: auto;
                height: 260px;
                object-fit: contain;
            }

            a {
                img {
                    width: 70px;
                }
            }

        }

        &__text-container {

            $font-size-building: 2.2rem;
            line-height: 30px;
            margin-top: 1rem;

            //overflow: hidden;

            @include media-breakpoint-up(md) {

                //overflow: unset;

                .slash {
                    font-size: 3rem;
                }

                &__title {
                    font-weight: 600;
                    font-size: 3rem;
                }
            }

            .slash {
                font-size: $font-size-building;
            }

            &__title {
                font-weight: 600;
                font-size: $font-size-building;
                margin-bottom: 0.5rem;
            }

            &__intro {
                font-weight: 500;
            }

            &__description {
                //margin-bottom: 2rem;

                &--highlight {

                    margin: -1rem;
                    padding: 1rem;

                }

            }

            .button--taxatie {
                width: fit-content;
            }

        }

    }


}