@import '../variables';

$map-height: 320px;

div#contactmap {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: $map-height;
    z-index: -1;
}

div#contactmapdesktop {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    width: 100%;
    height: 350px;
    z-index: 1;
}

.contactmapdesktop-push {
    height: 230px;
}
