// Roboto
$roboto-font-path: "roboto-fontface/fonts";
@import "roboto-fontface/css/roboto/sass/roboto-fontface";


// Override default Bootstrap variables
$primary: #D3F403;
$gradient: linear-gradient(293.28deg, #466144 0.32%, #0A1708 90.1%);
$btn-border-radius: 50px 0 50px 50px;
$border-radius: 30px 0 30px 30px;
$box-shadow: 3px 4px 4px 0px #00000026;
$headings-margin-bottom: 0;
$enable-smooth-scroll: true;

// BS modal
$modal-content-bg: #466144;
$modal-content-border-color: unset;

// BS settings
$enable-cssgrid: true;
$enable-shadows: true;

// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

$custom-theme-colors: (
    'main-green': #D3F403,
    'dark-green': #0C2804,
    'grey-green': #466144,
    'transp-green': rgba(12, 40, 4, 0.05),
    'gray': #4F4F4F,
    'light-green': #F2F4F1,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import "bootstrap/scss/bootstrap";

@each $class-name, $color in $custom-theme-colors {
    .text-#{$class-name} {
        color: #{$color};
    }

    .bg-#{$class-name} {
        background: #{$color};
    }
}

// Swiper
@import 'swiper/css/bundle';

:root {
    --swiper-navigation-size: 140px;
    --swiper-navigation-sides-offset: -40px;
}

// Ripple
@import "legit-ripple/css/ripple";
.ripple {
    &:hover, &:active {
        text-decoration: none;
    }
}

.legitRipple-ripple {
    background: rgba(0, 0, 0, .2);
}

$material-icon-font-path: '@corpoflow/material-icon-font/';
@import '@corpoflow/material-icon-font/MaterialIcons-Regular';

.highlight-block {
    display: block;
    background: #F2F4F1;
    margin: 1rem -1rem 0 -1rem;
    padding: 1rem;
}

@import 'aos/dist/aos';