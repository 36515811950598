.disable_scroll {
    height: 100% !important;
    overflow: hidden;
}

.transition {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    bottom: 0;
    left: 0;
    background: var(--bs-main-green);
    transform: translateY(0%);
    //transform: translateY(0%);
    clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        width: 350px;

        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;


        //path {
        //    opacity: 1;
        //    transform: translateY(120px);
        //}
    }

}