.news__posts {

    &__button-wrapper {
        grid-column: 2;
    }

    &__block {

        box-shadow: 0 0 5px #0000001A;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 30px 0 30px 30px;
        height: 100%;

        &__content {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
        }

        &__info {
            padding: 0 1.5rem;
        }

        &__image {
            padding: 0.5rem;


            img {
                height: 200px;
                object-fit: cover;
                width: 100%;
                border-radius: var(--bs-border-radius);

                @include media-breakpoint-up(sm) {
                    height: 250px;
                }

                @include media-breakpoint-up(md) {
                    height: 300px;
                }
            }

        }

        &__title {
            font-size: 1.5rem;
            font-weight: bold;
            word-wrap: break-word;
        }

        &__link {
            padding: 1rem 1.5rem;
        }

    }

}

.news__posts__carousel {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;


    .swiper-button {
        width: 36px;
        height: 36px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

        border-radius: 50%;

        &:focus {
            outline: none;
        }


        &.swiper-button-disabled {
            opacity: 1;
        }
    }

    .swiper-button-next {
        --swiper-navigation-sides-offset: -10px;
        //noinspection CssReplaceWithShorthandSafely
        background: url("../img/frontend/swiper-pointer-right.svg");
        //noinspection CssReplaceWithShorthandSafely
        background-repeat: no-repeat;
        //noinspection CssReplaceWithShorthandSafely
        background-position: center center;
    }

    .swiper-button-prev {
        --swiper-navigation-sides-offset: -10px;
        //noinspection CssReplaceWithShorthandSafely
        background: url("../img/frontend/swiper-pointer-left.svg");
        //noinspection CssReplaceWithShorthandSafely
        background-repeat: no-repeat;
        //noinspection CssReplaceWithShorthandSafely
        background-position: center center;
    }

    .swiper-button-next::after, .swiper-button-prev::after {
        content: "";
    }

    &__block {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        border-radius: var(--bs-border-radius);


        &__image {

            img {
                border-radius: var(--bs-border-radius);
            }

        }

    }

}
