@import '../variables';


.header {

    &--home {
        background: #FFFFFF;

        &__image-container {
            display: flex;
            justify-content: flex-end;
            position: relative;
            margin-top: -8rem;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                margin-top: -25vw;
            }

            img {
                width: 100%;
                height: 500px;
                object-fit: cover;
                position: relative;
                z-index: 101;

                @include media-breakpoint-up(lg) {
                    height: auto;
                }
            }

        }

        .header__wrapper {
            position: relative;
            z-index: 2;
        }

        .header-button {
            position: relative;
            z-index: 200;
        }

    }

    &--dit_zijn_wij {
        position: relative;
        z-index: 10;
    }

    &--dit_zijn_wij, &--jobs {
        background: var(--bs-transp-green);
    }

    //&--dit_zijn_wij {
    //
    //    .header__wrapper {
    //        padding-bottom: 10rem;
    //    }
    //
    //}

    &__wrapper {
        padding-bottom: 7rem;
    }
}

.sharp-edge {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);

    @include media-breakpoint-up(md) {
        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    }

    &--news_posts_detail {

        //clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);

        @include media-breakpoint-up(md) {
            clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);
        }
    }
}

.jobs_count {

    $orb_size: 30px;

    position: absolute;
    background: var(--bs-main-green);
    color: var(--bs-dark-green);
    border-radius: 50%;
    height: $orb_size;
    width: $orb_size;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    letter-spacing: 0;
    font-weight: 500;
    top: -12px;
    right: -26px;

    @include media-breakpoint-up(md) {
        top: 12px;
        right: -27px;
    }

    //@include media-breakpoint-up(md) {
    //    top: 12px;
    //    right: -27px;
    //}

    &--menu {
        top: -5px;
        right: -0.5rem;
        background: var(--bs-dark-green);
        color: var(--bs-main-green);
    }

}