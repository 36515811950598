@import '../variables';

$taxatie-bottom-spacing: -3.7rem;

.home-taxatie {

    margin-top: -3rem;
    padding: 4rem 0 2rem 0;

    @include media-breakpoint-up(lg) {
        margin-top: -5rem;
    }

    .home-taxatie__types {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        height: auto;

        &__column {
            padding: 0 30px;
        }

        &__block {
            display: block;
            text-decoration: none;
            height: auto;
            width: 100%;
            position: relative;
            border-radius: 30px 0 30px 30px;
            //box-shadow: var(--bs-box-shadow);
            //margin-bottom: 1rem;
            margin-bottom: 0;
            will-change: margin-bottom;
            transform: translate3d(0,0,0);
            -webkit-transform: translate3d(0,0,0);

            &__bar {
                display: flex;
                width: 100%;
                background: var(--bs-grey-green);
                padding: 1rem;
                border-radius: 50px 0 50px 50px;
                box-shadow: var(--bs-box-shadow);
                position: relative;
                z-index: 11;

                h2 {
                    margin-left: 15px;
                }

                &__icon {
                    display: block;
                    margin-left: auto;
                    will-change: transform;
                    transform: translate3d(0,0,0);
                    -webkit-transform: translate3d(0,0,0);
                }

            }

            &__description {
                position: relative;
                z-index: 10;
                color: white;
                background: rgba(70, 97, 68, 0.9);
                border-radius: 0 0 30px 30px;
                width: inherit;
                box-shadow: var(--bs-box-shadow);
                left: 0;
                height: 0;
                overflow: hidden;
                opacity: 0;
                bottom: 2rem;
                will-change: height, opacity;
                transform: translate3d(0,0,0);
                -webkit-transform: translate3d(0,0,0);

                p {
                    height: 100%;
                    display: block;
                    position: absolute;
                    padding: 3.5rem 2rem 2rem 2rem;
                }

            }

        }

        .button--taxatie {
            padding: 1rem;
            border: none;
        }

    }

}

.home-waarde {

    &__wrapper {
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
        padding: 10rem 0 5rem 0;


        @include media-breakpoint-up(md) {
            clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
            padding: 11rem 0 5rem 0;
        }

        @include media-breakpoint-up(lg) {
            clip-path: polygon(0 18%, 100% 0, 100% 100%, 0% 100%);
        }
    }

    &__building-types {

        &__block {

            h2 {
                font-weight: 400;
            }

            &__building {
                margin-bottom: 1rem;

                .bg-building-shape {
                    background: #F2F4F1;
                    border-radius: 40px 0 40px 40px;
                    width: 80%;
                    height: 100%;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                img {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
                    height: 200px;
                }

            }

        }

    }

}

.home-news {
    margin-top: -$section-spacing;
    padding-top: $section-spacing * 2;
}

.home-building {
    position: relative;
    z-index: -1;
    will-change: transform;
    //-webkit-transform: translate3d(0, 0, 0) !important;
    img {
        height: 500px;
        object-fit: cover;
    }
}

.home-customers {
    margin-top: -$section-spacing;
    padding: $section-spacing * 2 0;
    position: relative;
    z-index: 999;
}