@use "sass:math";

@mixin aspect-ratio($width, $height) {

    position: relative;
    overflow: hidden;
    clear: both;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: math.div($height, $width) * 100%;
    }

    &.expanded {

        img {
            object-fit: initial;
            position: absolute;
            left: -100%;
            right: -100%;
            top: -100%;
            bottom: -100%;
            margin: auto;
            min-height: 100%;
            min-width: 100%;
            transform: none;
            width: auto;
        }
    }

    img {

        object-fit: cover;
        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
    }
}

.sixteen-nine {
    @include aspect-ratio(16, 9);
}

.sixteen-ten {
    @include aspect-ratio(16, 10);
}

.four-three {
    @include aspect-ratio(4, 3);
}

// Utility classes for positioning
@for $i from 1 through 100 {

    // Positive
    .top--#{$i} {
        top: #{$i}px;
    }

    .right--#{$i} {
        right: #{$i}px;
    }

    .bottom--#{$i} {
        bottom: #{$i}px;
    }

    .left--#{$i} {
        left: #{$i}px;
    }

    // Negative
    .n-top--#{$i} {
        top: -#{$i}px;
    }

    .n-right--#{$i} {
        right: -#{$i}px;
    }

    .n-bottom--#{$i} {
        bottom: -#{$i}px;
    }

    .n-left--#{$i} {
        left: -#{$i}px;
    }

    .pr--#{$i} {
        padding-right: #{$i}rem;
    }

    .pl--#{$i} {
        padding-left: #{$i}rem;
    }

}

@for $x from 1 through 10 {

    //.section-

}