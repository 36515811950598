@import '../variables';

select {
    appearance: none;
    border: 1.5px solid $color-main-blue;
    box-sizing: border-box;
    border-radius: 100px;
    color: $color-main-blue;
    text-align: center;
    padding: 15px 70px 15px 20px;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    font-size: 16px;
    letter-spacing: 0.02em;
    background-image: url('data:image/svg+xml;utf8,<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.875 0.125L0.375 5.625H11.375L5.875 0.125Z" fill="#00A3F2"/><path d="M5.875 13.875L11.375 8.375L0.375001 8.375L5.875 13.875Z" fill="#00A3F2"/></svg>');
    background-repeat: no-repeat;
    outline: 0;
    background-position: right 20px center;
}

form {

    div.input.checkbox {
        position: relative;

        input[type="checkbox"] {
            position: absolute;
            top: 3px;

            & + label {
                margin: -3px 0 0 25px;

                & + .error-message {
                    margin: 0 0 0 25px;
                }
            }
        }
    }
}
