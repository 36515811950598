@import '../variables';

.home-customers {
    overflow-x: hidden;
}

.customer-swiper-container {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .swiper-button {
        width: 36px;
        height: 36px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

        border-radius: 50%;

        &:focus {
            outline: none;
        }


        &.swiper-button-disabled {
            opacity: 1;
        }
    }

    .swiper-button-next {
        //noinspection CssReplaceWithShorthandSafely
        background: url("../img/frontend/swiper-pointer-right.svg");
        //noinspection CssReplaceWithShorthandSafely
        background-repeat: no-repeat;
        //noinspection CssReplaceWithShorthandSafely
        background-position: center center;
    }

    .swiper-button-prev {
        //noinspection CssReplaceWithShorthandSafely
        background: url("../img/frontend/swiper-pointer-left.svg");
        //noinspection CssReplaceWithShorthandSafely
        background-repeat: no-repeat;
        //noinspection CssReplaceWithShorthandSafely
        background-position: center center;
    }

    .swiper-button-next::after, .swiper-button-prev::after {
        content: "";
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        //width: 162px;
        height: 146px;
        border-radius: 5px;

        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        img {
            max-width: 75%;
            max-height: 90%;
            filter: grayscale(100%);
            transition: filter 1s ease-in-out;

            &:hover {
                filter: grayscale(0%);
            }
        }

    }

    .swiper-pagination {

        position: relative;
        margin-bottom: -1rem;
        margin-top: 4rem;

        .swiper-pagination-bullet {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: #b7d6f4;
            margin: 0 10px 0 10px;
            border-radius: 50%;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: #012647;
        }
    }
}

