@import 'variables';

body {
    font-family: 'Roboto', sans-serif;

    &.modal-open {
        overflow: hidden;
    }

    &.offcanvas-open {

        overflow: hidden;

        &:after {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
            content: '';
            background: rgba(0, 0, 0, .5);
        }
    }

}


.text-body-large {
    color: $color-body-large-text;
    font-size: $font-size-body-large;
}

h2, .heading-2 {
    font-weight: bold;
    line-height: 27px;
    font-size: 22px;
}


.ripple {
    outline: none;

    &:focus, &:active {
        outline: none;
    }
}

section.page_header {
    margin-top: 2rem;

    h1 {
        color: #FFFFFF;
        font-weight: 900;
        font-size: 4rem;
        line-height: 34px;
        margin: 1rem 0 2rem 0;
    }

    h4 {
        margin-top: 1rem;
    }

    h4, span {
        font-weight: 400;
        color: #FFFFFF;
    }

    span.about_desc {
        padding: 0 5rem;
    }


    p {

        margin: 10px 0 0 0;
        font-size: $font-size-body-large;
        line-height: 24px;
        font-weight: 300;
        opacity: .8;
        color: white;
    }

    @include media-breakpoint-up(lg) {
        margin: 85px 0 0 0;
    }

    @include media-breakpoint-down(xs) {
        h1 {
            line-height: 60px;
            font-size: 3rem;
        }
    }
}

.content-container {


    &--home {
        //margin-top: -30vw;
        position: relative;

        //@include media-breakpoint-up(sm) {
        //    margin-top: -15rem;
        //}
        //
        @include media-breakpoint-up(md) {
            //margin-top: -25vw;
        }

        @include media-breakpoint-up(lg) {
           //margin-top: -30vw;
        }

        @include media-breakpoint-up(xl) {
            //margin-top: -33vw;
        }
    }
}

.section__heading {
    font-size: 3rem;
    line-height: 3rlh;
    text-align: center;
    margin-bottom: 0.5rem;
}

.section__intro {
    font-size: 1rem;
    margin-bottom: 1rem;
    display: block;
}

main section {
    padding: $section-spacing 0;
}