@import '../variables';

section.contact_title {
    margin: 226px 0 0 0;

    @include media-breakpoint-up(lg) {
        margin: 0;
    }

    .title_row {
        padding: 50px 0 0 0;
    }
}
