@import '../variables';

#offcanvas-menu {
    position: fixed;
    left: -90%;
    width: 90%;
    height: 100%;
    z-index: 1000;
    transition: left ease-in-out .2s;
    overflow-y: auto;
    @extend .bg-gray;

    .close_offcanvas {
        float: right;
        margin: 10px 10px 0 0;
        cursor: pointer;
    }

    .logo {
        margin: auto;
        padding: 50px 0 30px 0;
        text-align: center;
        width: 250px;

        img {
            width: 100%;
        }
    }

    ul.menu {
        display: grid;
        justify-content: center;
        justify-items: start;
        margin-top: 1rem;
        gap: 1rem;
        padding: 0;
        list-style: none;

        li {
            width: 100%;

            &.menu--vacatures {
                position: relative;
            }

            &.active, &:hover, &:active {
                a {
                    text-decoration: none;
                    background: var(--bs-main-green);
                }
            }

            a {
                display: block;
                line-height: 24px;
                font-size: 20px;
                color: var(--bs-dark-green);
                padding: 10px 60px;
                border-radius: 50px 0 50px 50px;
                text-decoration: none;
            }

        }

    }

    .socials {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: center;


        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin: 0 1px;

            img {
                width: 100%;
                height: 100%;
            }

        }

    }

    body.offcanvas-open & {
        left: 0;
    }
}

