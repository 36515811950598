@import '../variables';

.welcome_message {

    margin: 25px 0 0 0;


    h1 {
        color: white;
        font-weight: normal;
        font-size: $font-size-h1;
        line-height: 50px;
        letter-spacing: unset;

        @include media-breakpoint-up(sm) {
            letter-spacing: 2px;
            font-size: 3rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 4.5rem;
            line-height: 6.5rem;
        }
    }

    &.welcome_message--home {

        .header__prefix {
            font-size: 1.5rem;

            @include media-breakpoint-up(sm) {
                font-size: 2.5rem;
            }

            font-weight: 300;

        }

        .typewrite-container {

            @media only screen and (max-width: 300px) {
                overflow: hidden;
            }

            .h1 {
                text-align: center;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }

            .slash--home {
                will-change: transform, filter;
                transform: translate(-30px, 0%);
                -webkit-transform: translate3d(-30px,0,0);

                @media only screen and (max-width: 300px) {
                    overflow: hidden;
                }
            }

            .typewrite-wrapper {
                display: flex;
                align-items: center;
            }

            .typewrite-text {

                width: auto;

                div {
                    will-change: opacity, transform;
                    transform: translate3d(0,0,0);
                    -webkit-transform: translate3d(0,0,0);
                    -webkit-backface-visibility: hidden;
                    opacity: 0;
                }
            }

            .typewrite-caret {
                border-right: 2px solid rgba(255, 255, 255, .75);
                height: 2rem;
            }

            @include media-breakpoint-up(lg) {
                align-items: flex-start;
            }

            div {
                font-weight: bold;
                font-size: 2rem;
                line-height: 4rem;
                position: relative;
                z-index: 2;
                display: inline-block;

                @include media-breakpoint-up(sm) {
                    font-size: 3rem;
                }

                @include media-breakpoint-up(md) {
                    font-size: 4.5rem;
                    line-height: 6.5rem;
                }
            }

            @include media-breakpoint-up(md) {
                height: 7rem;
            }
        }

        .btn {
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(lg) {
                margin-left: unset;
            }
        }
    }

    p {

        font-size: $font-size-body-large;
        line-height: 24px;
        font-weight: 300;
        opacity: .8;
        color: white;
        text-align: center;
        margin: 10px 0 1rem;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

    }

    @include media-breakpoint-up(lg) {
        margin: 85px 0 0 0;
    }
}
